import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { BackgroundTexture } from '../components/background-texture';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { ClientReview } from '../components/client-review';
import { InstagramWidget } from '../components/instagram-widget';
import { ContactSection } from '../components/contact-section';
import { CTA } from '../components/cta';
import ServicesCard from '../components/services-card';
import { facialService, waxAndBeauty } from '../components/services';
import { Link } from 'gatsby';

function OurTreatmentsPage(): React.ReactElement {
  return (
    <>
      <SEO
        title="Our treatments"
        description="Longing for an amazing organic facelift that you desire. Adore Beauty and Cosmetic Tattoo creates an experience like no other with our exquisite beauty treatment. Jess, the founder of Adore Beauty and Cosmetic Tattoo, has more than 18 years in the beauty industry and specialises in brow makeovers."
      />
      <Layout>
        <Hero />
        <Facials />
        <WaxAndBeauty />
        <CTA
          text="Interested in Cosmetic Tattooing?"
          path="/cosmetic-tattooing/"
        />
        <ClientReview />
        <InstagramWidget />
        <ContactSection />
      </Layout>
    </>
  );
}

function Hero(): React.ReactElement {
  return (
    <div className="relative hidden max-h-screen sm:block">
      {/* <div className="absolute md:hidden -top-24">
        <StaticImage
          quality={90}
          src="../images/hero-bg.png"
          alt=""
          width={700}
          className="flex-1 w-full object-full"
        />
      </div> */}
      <div className="absolute inset-0 flex">
        <StaticImage
          quality={90}
          src="../images/treatments-hero.png"
          alt=""
          className="flex-1 object-cover"
        />
        <div className="absolute inset-0 flex">
          <div className="flex-1 hero-bg-filter" />
        </div>
      </div>
      <div className="relative w-full px-4 py-24 mx-auto md:py-48 sm:px-6 lg:px-8 max-w-screen-2xl">
        <h1 className="text-4xl font-semibold text-center md:text-left text-custom-gradient">
          Our Treatments
        </h1>
      </div>
    </div>
  );
}

function Facials(): React.ReactElement {
  return (
    <div className="relative block w-full">
      <BackgroundTexture position="left" />
      <div className="relative px-4 py-16 mx-auto space-y-5 md:py-24 max-w-screen-2xl sm:px-6 lg:px-8">
        <div>
          <h2 className="text-3xl font-semibold text-center md:mb-8">
            <span className="block text-custom-gradient">Facials</span>
          </h2>

          {facialService.map((facial) => (
            <div key={facial.id}>
              <ServicesCard
                name={facial.name}
                price={facial.price}
                description={facial.description}
                image={facial.image}
                imagePosition="left"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function WaxAndBeauty(): React.ReactElement {
  return (
    <div className="relative block w-full bg-pink-lightest">
      <BackgroundTexture position="right" />
      <div className="absolute inset-0 top-96">
        <BackgroundTexture position="left" />
      </div>
      <div className="z-10 py-12 mx-auto md:w-9/12 md:py-6">
        <div className="relative px-4 py-12 mx-auto md:py-24 max-w-screen-2xl sm:px-6 lg:px-8">
          <div>
            <h2 className="text-3xl font-semibold text-center">
              <span className="block text-custom-gradient">Wax and Beauty</span>
            </h2>
            <div className="mt-16">
              <div className="flex items-center justify-between mx-auto lg:w-9/12 lg:px-8">
                <span className="block text-2xl font-semibold text-custom-gradient">
                  Touch Ups
                </span>
                <Link
                  to="/contact-us/#contact"
                  className="px-10 py-3 text-sm font-semibold border-2 rounded-full text-pink border-pink"
                >
                  Enquire Now
                </Link>
              </div>
              <div className="flex justify-center">
                <div className="grid-flow-col grid-cols-2 grid-rows-6 md:grid gap-x-28 xl:gap-x-48">
                  {waxAndBeauty.map((item) => (
                    <div
                      key={item.id}
                      className="grid grid-cols-4 my-5 text-lg font-semibold xl:text-2xl gap-x-16"
                    >
                      <span className="col-span-3">{item.name}</span>
                      <span className="col-span-1">{`$${item.price}`}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTreatmentsPage;
