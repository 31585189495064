import { nanoid } from 'nanoid';

export const waxAndBeauty = [
  {
    name: 'Brow sculpt',
    price: 25,
    id: nanoid(),
  },
  {
    name: 'Brow sculpt and hybrid dye',
    price: 50,
    id: nanoid(),
  },
  {
    name: 'Hybrid dye only',
    price: 30,
    id: nanoid(),
  },
  {
    name: 'Classic brow tint',
    price: 15,
    id: nanoid(),
  },
  {
    name: 'Lip wax',
    price: 15,
    id: nanoid(),
  },
  {
    name: 'Chin wax',
    price: 15,
    id: nanoid(),
  },
  {
    name: 'Brow Lamination and sculpt',
    price: 90,
    id: nanoid(),
  },
  {
    name: 'Brow lamination, Sculpt, and classic tint',
    name2: 'and classic tint',
    price: 105,
    id: nanoid(),
  },
  {
    name: 'Brow lamination, sculpt and hybrid dye',
    price: 115,
    id: nanoid(),
  },
  {
    name: 'Lash tint',
    price: 25,
    id: nanoid(),
  },
  {
    name: 'Lash lift',
    price: 90,
    id: nanoid(),
  },
  {
    name: 'Lash lift with tint',
    price: 100,
    id: nanoid(),
  },
];
