import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { nanoid } from 'nanoid';

export const facialService = [
  {
    id: nanoid(),
    name: 'Express facial',
    price: 90,
    description: (
      <div>
        A 45 min facial for those short on time. Includes a double cleanse,
        exfoliation, short massage and mask.
      </div>
    ),
    image: <StaticImage src="../../images/facials/express-facial.jpg" alt="" />,
  },
  {
    id: nanoid(),
    name: 'Hydrate and glow facial',
    price: 120,
    description: (
      <div>
        A 1 hour facial designed to buff away dead cells, plump and hydrate the
        skin. Includes face neck and décolletage.
      </div>
    ),
    image: (
      <StaticImage
        src="../../images/facials/hydrate-and-glow-facial.jpg"
        alt=""
      />
    ),
  },
  {
    id: nanoid(),
    name: 'Skin needling facial treatment 1hr',
    price: 250,
    description: (
      <div>
        <p>( book 3 and receive your 4th free)</p>
        <ul className="pl-6 list-disc">
          <li>Reduce fine lines and wrinkles</li>
          <li>Smooth uneven skin tone and pigment</li>
          <li>Reduce the appearance of scar tissue</li>
          <li>Increase the skin’s natural production of collagen</li>
        </ul>
      </div>
    ),
    image: (
      <StaticImage
        src="../../images/facials/skin-needling-facial-treatment-1hr.jpg"
        alt=""
      />
    ),
  },
];
