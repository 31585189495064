import { ArrowLeftIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

type Position = 'left' | 'right';

interface ServicesCardProps {
  name: string;
  price?: number;
  description: React.ReactElement;
  buttonOutline?: boolean;
  buttonLink?: string;
  image: React.ReactElement;
  imagePosition?: Position;
}

const filledButton =
  'inline-flex px-12 py-3 text-sm font-semibold text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark undefined';
const outlineButton =
  'inline-flex px-12 py-3 text-sm font-semibold text-pink rounded-full border border-pink';

function ServicesCard({
  name,
  price,
  description,
  buttonOutline = false,
  buttonLink = '/contact-us/#contact',
  image,
  imagePosition = 'left',
}: ServicesCardProps): React.ReactElement {
  if (imagePosition === 'left') {
    return (
      <div className="z-10 py-8 mx-auto md:w-8/12 md:py-6">
        <div className="items-center grid-cols-8 gap-12 md:grid">
          <div className="col-span-3 py-8 mx-auto w-52 xl:col-span-2 md:py-4">
            <div className="max-w-xs mx-auto">
              <div className="flex justify-center overflow-hidden rounded-full safari-hack">
                {image}
              </div>
            </div>
          </div>
          <div className="md:col-span-5 xl:col-span-6">
            <div className="flex flex-col items-center md:block">
              <div className="justify-between text-xl font-semibold text-center md:flex md:text-2xl md:text-left">
                <h3>{name}</h3>
                <span>{price ? `$${price}` : ''}</span>
              </div>
              <div className="my-6 text-sm leading-7">{description}</div>
              <Link
                to={buttonLink}
                className={classNames(
                  buttonOutline ? outlineButton : filledButton
                )}
              >
                Enquire Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="z-10 py-8 mx-auto md:w-9/12 md:py-6">
        <div className="items-center grid-cols-8 gap-12 md:grid">
          <div className="md:col-span-5 xl:col-span-6">
            <div className="flex flex-col items-center md:block">
              <div className="justify-between text-xl font-semibold text-center md:flex md:text-2xl md:text-left">
                <h3>{name}</h3>
                <span>{price ? `$${price}` : ''}</span>
              </div>
              <div className="my-6 text-sm leading-7">{description}</div>
              <Link
                to={buttonLink}
                className={classNames(
                  buttonOutline ? outlineButton : filledButton
                )}
              >
                Enquire Now
              </Link>
            </div>
          </div>
          <div className="col-span-3 py-8 mx-auto w-52 xl:col-span-2 md:py-4">
            <div className="max-w-xs mx-auto">
              <div className="flex items-center justify-center overflow-hidden rounded-full safari-hack">
                {image}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesCard;
